import axios from "axios";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SWRConfig } from "swr";
import "../styles/globals.css";
import "../styles/styles.css";
import { pageView } from "../lib/google_analytics/pageView";

const errorHandler = (error: Error, info: { componentStack: string }) => {
  const sendErrorToSlack = async () => {
    if (!!!process.env.NEXT_PUBLIC_SLACK_WEBHOOK) {
      return;
    }
    const client = axios.create({
      transformRequest: [
        (data, headers) => {
          if (!!headers) {
            delete headers["Content-Type"];
          }
          return data;
        },
      ],
    });
    client.post(
      process.env.NEXT_PUBLIC_SLACK_WEBHOOK,
      JSON.stringify({ text: JSON.stringify({ error: error, info: info }) }),
      {
        withCredentials: false,
      }
    );
  };

  sendErrorToSlack();
};

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: String) => {
      pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SWRConfig
      value={{
        onError: (error, key) => {
          const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK;
          if (webhookUrl !== undefined) {
            axios.post(webhookUrl, JSON.stringify(error), {
              withCredentials: false,
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        },
      }}
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => router.reload()}
        onError={errorHandler}
      >
        <Component {...pageProps} />
      </ErrorBoundary>
    </SWRConfig>
  );
}

export default MyApp;
